
    import _Handlebars from 'handlebars/runtime';

    var Handlebars = _Handlebars.default;
    import "/var/canvas/ui/shared/external-tools/jst/_external_tool_menuitem.handlebars";
import "@canvas/handlebars-helpers/index.js";

    var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
    var name = '/var/canvas/ui/shared/external-tools/jst/-external-tools-menu';
    templates[name] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); partials = this.merge(partials, Handlebars.partials); data = data || {};
  var buffer = "", stack1, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n  ";
  stack1 = helpers.each.call(depth0, depth0, {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n  </ul>\n";
  return buffer;
  }
function program2(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    <li role=\"presentation\">\n      ";
  stack1 = self.invokePartial(partials['ui/shared/external-tools/jst/_external_tool_menuitem.handlebars'], 'ui/shared/external-tools/jst/_external_tool_menuitem.handlebars', depth0, helpers, partials, data);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </li>\n  ";
  return buffer;
  }

  stack1 = helpers['if'].call(depth0, depth0, {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n";
  return buffer;
  });
    
      Handlebars.registerPartial('ui/shared/external-tools/jst/_external_tools_menu.handlebars', templates['/var/canvas/ui/shared/external-tools/jst/-external-tools-menu']);
    ;
    ;
    export default templates[name];
  