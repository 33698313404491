
    import _Handlebars from 'handlebars/runtime';

    var Handlebars = _Handlebars.default;
    import "/var/canvas/ui/shared/external-tools/jst/_external_tools_menu.handlebars";
import "@canvas/i18n";
import "@canvas/handlebars-helpers/index.js";

    var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
    var name = '/var/canvas/ui/features/wiki-page-show/jst/WikiPage';
    templates[name] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); partials = this.merge(partials, Handlebars.partials); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n<div class=\"header-bar-outer-container\">\n  <div class=\"sticky-toolbar\" data-sticky>\n    <div class=\"header-bar page-toolbar ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.course_home), {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">\n      <div class=\"page-toolbar-start\">\n        <div class=\"page-heading\">\n          ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.course_home), {hash:{},inverse:self.program(6, program6, data),fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n        ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.course_home), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n        <div class=\"page-toolbar-end\">\n          ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.course_home), {hash:{},inverse:self.noop,fn:self.program(12, program12, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          <div class=\"buttons\">\n            ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.cannot_edit_by_master_course), {hash:{},inverse:self.noop,fn:self.program(21, program21, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.ACCESS_GEAR_MENU), {hash:{},inverse:self.noop,fn:self.program(24, program24, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"page-changed-alert\" role=\"alert\" aria-atomic=\"true\" aria-live=\"polite\"></div>\n  </div>\n</div>\n<div id=\"direct-share-mount-point\"></div>\n";
  return buffer;
  }
function program2(depth0,data) {
  
  
  return "as-course-home";
  }

function program4(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n          <h2 class=\"course-title\" title=\"";
  if (helper = helpers.course_title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.course_title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.course_title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.course_title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h2>\n          ";
  return buffer;
  }

function program6(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.VIEW_ALL_PAGES), {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n          ";
  return buffer;
  }
function program7(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n              <a class=\"btn view_all_pages\" href=\"";
  if (helper = helpers.wiki_pages_path) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.wiki_pages_path); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.buttons.view_all_pages", "View All Pages", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.buttons.view_all_pages", "View All Pages", options)))
    + "</a>\n            ";
  return buffer;
  }

function program9(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n          ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.front_page), {hash:{},inverse:self.noop,fn:self.program(10, program10, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        ";
  return buffer;
  }
function program10(depth0,data) {
  
  
  return "\n            <div class=\"front-page\"></div>\n          ";
  }

function program12(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n          <div class=\"blueprint-label\"></div>\n          <div class=\"publishing\">\n            <div class=\"published\">\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.PUBLISH), {hash:{},inverse:self.program(15, program15, data),fn:self.program(13, program13, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            </div>\n          </div>\n          ";
  return buffer;
  }
function program13(depth0,data) {
  
  
  return "\n                <span class=\"publish-button\"></span>\n              ";
  }

function program15(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.VIEW_UNPUBLISHED), {hash:{},inverse:self.noop,fn:self.program(16, program16, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n              ";
  return buffer;
  }
function program16(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                  ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.published), {hash:{},inverse:self.program(19, program19, data),fn:self.program(17, program17, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                ";
  return buffer;
  }
function program17(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n                    <span class='published-status published'>\n                      <i class=\"icon-publish icon-Solid\"></i><span class=\"hide-when-small\"> "
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.published_indicator", "Published", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.published_indicator", "Published", options)))
    + "</span>\n                    </span>\n                  ";
  return buffer;
  }

function program19(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n                    <span class='published-status unpublished'>\n                      <i class=\"icon-unpublished\"></i><span class=\"hide-when-small\"></span> "
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.unpublished_indicator", "Not Published", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.unpublished_indicator", "Not Published", options)))
    + "</span>\n                    </span>\n                  ";
  return buffer;
  }

function program21(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n              ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.UPDATE_CONTENT), {hash:{},inverse:self.noop,fn:self.program(22, program22, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n            ";
  return buffer;
  }
function program22(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n                <a href=\"";
  if (helper = helpers.wiki_page_edit_path) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.wiki_page_edit_path); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"btn edit-wiki\"><i class=\"icon-edit\"></i><span class=\"hide-when-small\"> "
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.buttons.edit", "Edit", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.buttons.edit", "Edit", options)))
    + "</span></a>\n              ";
  return buffer;
  }

function program24(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n              <div class=\"inline-block\">\n                <a class=\"btn al-trigger\" tabindex=\"0\" role=\"button\" href=\"#\">\n                  <i class=\"icon-more\" aria-hidden=\"true\"></i>\n                  <span class=\"screenreader-only\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.toolbar_menu.settings", "Settings", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.toolbar_menu.settings", "Settings", options)))
    + "</span>\n                </a>\n                <ul class=\"al-options\">\n                  ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.cannot_delete_by_master_course), {hash:{},inverse:self.noop,fn:self.program(25, program25, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                  ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.READ_REVISIONS), {hash:{},inverse:self.noop,fn:self.program(31, program31, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                  ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.front_page), {hash:{},inverse:self.noop,fn:self.program(33, program33, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                  ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.DIRECT_SHARE), {hash:{},inverse:self.noop,fn:self.program(36, program36, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                  ";
  stack1 = self.invokePartial(partials['ui/shared/external-tools/jst/_external_tools_menu.handlebars'], 'ui/shared/external-tools/jst/_external_tools_menu.handlebars', (depth0 && depth0.wiki_page_menu_tools), helpers, partials, data);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                </ul>\n              </div>\n            ";
  return buffer;
  }
function program25(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                    ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.DELETE), {hash:{},inverse:self.noop,fn:self.program(26, program26, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                  ";
  return buffer;
  }
function program26(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n                      <li><a href=\"#\" class=\"icon-trash delete_page";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.deletable), {hash:{},inverse:self.noop,fn:self.program(27, program27, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.deletable), {hash:{},inverse:self.noop,fn:self.program(29, program29, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += ">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.delete_wiki", "Delete", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.delete_wiki", "Delete", options)))
    + "</a></li>\n                    ";
  return buffer;
  }
function program27(depth0,data) {
  
  
  return " disabled";
  }

function program29(depth0,data) {
  
  
  return "aria-disabled=\"true\"";
  }

function program31(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n                    <li><a href=\"";
  if (helper = helpers.wiki_page_history_path) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.wiki_page_history_path); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"icon-clock view_page_history\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.view_page_history_wiki", "View Page History", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.view_page_history_wiki", "View Page History", options)))
    + "</a></li>\n                  ";
  return buffer;
  }

function program33(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n                    ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.UPDATE_CONTENT), {hash:{},inverse:self.noop,fn:self.program(34, program34, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                  ";
  return buffer;
  }
function program34(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n                      <li><a href=\"#\" class=\"icon-bookmark use-as-front-page-menu-item";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.published), {hash:{},inverse:self.noop,fn:self.program(27, program27, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" title=\""
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'frontPageText': ((depth0 && depth0.frontPageText)),
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.menu.use_front_page", "Use as %{frontPageText}", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.menu.use_front_page", "Use as %{frontPageText}", options)))
    + "\" ";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.published), {hash:{},inverse:self.noop,fn:self.program(29, program29, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += ">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'frontPageText': ((depth0 && depth0.frontPageText)),
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.menu.use_front_page", "Use as %{frontPageText}", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.menu.use_front_page", "Use as %{frontPageText}", options)))
    + "</a></li>\n                    ";
  return buffer;
  }

function program36(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n                    <li><a href=\"#\" class=\"icon-user direct-share-send-to-menu-item\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_inferred_key': (true),
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "send_to_b940900a", "Send To...", options) : helperMissing.call(depth0, "t", "send_to_b940900a", "Send To...", options)))
    + "</a></li>\n                    <li><a href=\"#\" class=\"icon-duplicate direct-share-copy-to-menu-item\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_inferred_key': (true),
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "copy_to_64f8456b", "Copy To...", options) : helperMissing.call(depth0, "t", "copy_to_64f8456b", "Copy To...", options)))
    + "</a></li>\n                  ";
  return buffer;
  }

function program38(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n    <b>"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("wiki.wiki_page")
  },data:data},helper ? helper.call(depth0, "wiki.wiki_page.wiki.todo_date", "To-Do Date:", options) : helperMissing.call(depth0, "t", "wiki.wiki_page.wiki.todo_date", "To-Do Date:", options)))
    + " "
    + escapeExpression((helper = helpers.tDateToString || (depth0 && depth0.tDateToString),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.todo_date), "date_at_time", options) : helperMissing.call(depth0, "tDateToString", (depth0 && depth0.todo_date), "date_at_time", options)))
    + "</b>\n  ";
  return buffer;
  }

function program40(depth0,data) {
  
  
  return "\n    <div class=\"spinner\"></div>\n    <div class=\"lock_explanation\"></div>\n  ";
  }

function program42(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n    "
    + escapeExpression((helper = helpers.convertApiUserContent || (depth0 && depth0.convertApiUserContent),options={hash:{},data:data},helper ? helper.call(depth0, (depth0 && depth0.body), options) : helperMissing.call(depth0, "convertApiUserContent", (depth0 && depth0.body), options)))
    + "\n  ";
  return buffer;
  }

  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.CAN)),stack1 == null || stack1 === false ? stack1 : stack1.VIEW_TOOLBAR), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n<div class=\"show-content user_content clearfix\">\n  <h1 class=\"page-title\">";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h1>\n  ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.todo_date), {hash:{},inverse:self.noop,fn:self.program(38, program38, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n  ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.locked_for_user), {hash:{},inverse:self.program(42, program42, data),fn:self.program(40, program40, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</div>\n";
  return buffer;
  });
    ;
    ;
    export default templates[name];
  