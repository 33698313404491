
    import _Handlebars from 'handlebars/runtime';

    var Handlebars = _Handlebars.default;
    import "@canvas/i18n";
import "@canvas/handlebars-helpers/index.js";

    var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
    var name = '/var/canvas/ui/shared/forms/jst/DialogFormWrapper';
    templates[name] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"outlet\"></div>\n\n<div class=\"button-container\">\n  <button class=\"btn dialog_closer\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("dialog_form_wrapper")
  },data:data},helper ? helper.call(depth0, "dialog_form_wrapper.cancel", "Cancel", options) : helperMissing.call(depth0, "t", "dialog_form_wrapper.cancel", "Cancel", options)))
    + "</button>\n  <button\n    class=\"btn btn-primary\"\n    data-text-while-loading='"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("dialog_form_wrapper")
  },data:data},helper ? helper.call(depth0, "dialog_form_wrapper.saving", "Saving...", options) : helperMissing.call(depth0, "t", "dialog_form_wrapper.saving", "Saving...", options)))
    + "'\n    type=\"submit\"\n  >"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_scope': ("dialog_form_wrapper")
  },data:data},helper ? helper.call(depth0, "dialog_form_wrapper.save_settings", "Save Settings", options) : helperMissing.call(depth0, "t", "dialog_form_wrapper.save_settings", "Save Settings", options)))
    + "</button>\n\n";
  return buffer;
  });
    ;
    ;
    export default templates[name];
  